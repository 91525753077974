import React from 'react';
import { createPortal } from 'react-dom';
import { useReactFlow } from 'reactflow';
import {
  ContentFiltersProvider,
  defaultState as defaultContentFiltersState,
} from 'contexts/content/filters';
import { Box, ConfirmModal } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body, color, Heading } from 'DesignSystem/Typography';
import { useJourneyState } from 'contexts/journey';
import { usePortal } from 'hooks/usePortal';
import { CommunicationStep, EmailChannel } from 'models/journeys/journey';
import { Button } from '@socialchorus/shared-ui-components';
import * as Text from 'DesignSystem/Typography';
import { CloseButton } from '../JourneyCanvasHeader/Buttons';
import { DrawerState } from '../JourneyDrawer/drawer';
import { ContentListState, Drawer } from './Drawer';
import { ContentListTabs, Tab } from './ContentListTabs';
import { JourneyContentList } from './JourneyContentList';
import styles from './journey-content-list-drawer.module.css';
import { centerY } from '../JourneyCanvas/utils/node';
import { useJourneyValidationErrors } from '../JourneyErrors/useJourneyValidationErrors';
import { useJourneyContentDesigner } from '../JourneyContentDesigner/JourneyContentDesignProvider';

const CAMPAIGNS_TAB: Tab = {
  label: 'Campaigns',
  id: 'content',
};

const TEMPLATES_TAB: Tab = {
  label: 'Templates',
  id: 'template',
};

const TABS = [CAMPAIGNS_TAB, TEMPLATES_TAB];

export const JourneyContentListDrawer: React.FC = () => {
  const portal = usePortal('journey-content-list-drawer');
  const flowInstance = useReactFlow();

  const {
    activeStep,
    contentListState,
    drawerState,
    setContentListState,
    setDrawerState,
    updateStep,
    setActiveStepId,
  } = useJourneyState();
  const { resolveErrors } = useJourneyValidationErrors();
  const { createDesign, inlineEditingEnabled } = useJourneyContentDesigner();

  const [activeTabId, setActiveTabId] = React.useState<Tab['id']>(
    CAMPAIGNS_TAB.id
  );

  const [pendingCommunication, setPendingCommunication] = React.useState<
    CommunicationStep
  >();

  const handleReplacementConfirmation = React.useCallback(() => {
    if (!pendingCommunication) return;

    updateStep({ ...pendingCommunication, approved: false });
    setPendingCommunication(undefined);

    if (drawerState === DrawerState.Closed) setDrawerState(DrawerState.Partial);
  }, [drawerState, pendingCommunication, setDrawerState, updateStep]);

  const onSelectContent = React.useCallback(
    (communication: Partial<CommunicationStep>) => {
      const resolvedErrors = [];
      if (!activeStep || activeStep.type !== 'communication') return;
      const newCommunication = { ...communication };

      if (newCommunication.channels && newCommunication.channels.length === 0) {
        delete newCommunication.channels;
      }

      activeStep.name = newCommunication.title ?? 'Untitled';
      resolvedErrors.push('title');
      resolvedErrors.push('designProcessingErrors');

      if (newCommunication.channels) {
        const emailChannel = newCommunication.channels.find(
          (c) => c.name === 'email'
        ) as EmailChannel;

        if (emailChannel && emailChannel.emailSenderAlias) {
          emailChannel.programContactAddressId = parseInt(
            emailChannel.emailSenderAlias.id,
            10
          );
        }

        if (emailChannel && emailChannel.subject) {
          resolvedErrors.push('emailChannelSubject');
        }
        if (emailChannel && emailChannel.previewText) {
          resolvedErrors.push('emailChannelPreview');
        }
        if (
          (emailChannel && emailChannel.emailSenderAlias) ||
          emailChannel.programContactAddressId
        ) {
          resolvedErrors.push('emailChannelAddress');
        }
      }

      if (activeStep.designId) {
        setPendingCommunication({
          ...activeStep,
          ...newCommunication,
          channels: newCommunication.channels || [
            {
              name: 'email',
              previewText: '',
              subject: '',
            },
          ],
        });
        resolveErrors(activeStep.id, resolvedErrors);
        return;
      }

      updateStep({
        ...activeStep,
        ...newCommunication,
      });

      if (newCommunication.designId) {
        resolvedErrors.push('designId');
      }
      if (drawerState === DrawerState.Closed) {
        // If we close the drawer before the `copyDesign` promise resolves, the active step will be set to undefined
        // and we will not render the drawer HTML.
        setActiveStepId(activeStep.id);
        setDrawerState(DrawerState.Partial);
        centerY(flowInstance, activeStep.id);
      }
      resolveErrors(activeStep.id, resolvedErrors);
    },
    [
      activeStep,
      drawerState,
      flowInstance,
      resolveErrors,
      setActiveStepId,
      setDrawerState,
      updateStep,
    ]
  );
  if (!portal.ready) return null;

  const closeDrawer = () => setContentListState(ContentListState.Closed);
  const isCommunicationStep = activeStep && activeStep.type === 'communication';

  return createPortal(
    <Drawer state={contentListState} onOverlayClick={closeDrawer}>
      <Flex column className={styles.journeyContentListContainer}>
        <Flex spread>
          <Heading semibold>
            {isCommunicationStep && activeStep.designId ? 'Replace' : 'Add'}{' '}
            Content
          </Heading>
          <CloseButton handleClose={closeDrawer} />
        </Flex>
        {inlineEditingEnabled && (
          <Flex column start className={styles.journeyContentListActions}>
            <Button
              label="Create New"
              onClick={createDesign}
              variant="primary"
            />
            <Box padding={[24, 0]}>
              <Text.Body>OR</Text.Body>
            </Box>
            <Text.Subheading semibold>Select existing</Text.Subheading>
          </Flex>
        )}

        <ContentListTabs
          tabs={TABS}
          activeId={activeTabId}
          onSelectTab={setActiveTabId}
          className={styles.journeyContentListTabs}
        />
        <ContentFiltersProvider
          skipQueryParams
          defaultFiltersState={defaultContentFiltersState(defaultFiltersState)}
        >
          <JourneyContentList
            onSelectContent={onSelectContent}
            contentType={activeTabId}
          />
        </ContentFiltersProvider>
      </Flex>
      {pendingCommunication && (
        <ConfirmModal
          onConfirm={handleReplacementConfirmation}
          confirmLabel="Replace"
          title="Replace Content"
          onCancel={() => setPendingCommunication(undefined)}
        >
          <Body color={color.gray60}>
            This will replace your current Communication content and settings
            with the content and settings from the campaign you have chosen.
            This cannot be undone.
          </Body>
        </ConfirmModal>
      )}
    </Drawer>,
    portal.target
  );
};

const defaultFiltersState = {
  standard: {
    publicationState: {
      values: ['published', 'scheduled', 'draft'],
      isVisible: true,
    },
    studioVersions: {
      isVisible: false,
    },

    // Hack to not remove the template_id from the url.
    // This is not really a filter option, but starting a Journey from a template
    // adds a template_id query param to the url, which is used to fetch the
    // journey template.  The content filters in the content list selector updates
    // the query params based on the selected filters.  Having the `template_id` key
    // here keeps the query param in the url, and the `isVisible` property being false
    // does not allow it to be selected as a filter option in the content list selector.
    template_id: {
      name: 'template_id',
      field: 'template_id',
      label: 'Template',
      values: [],
      isVisible: false,
    },
  },
};
