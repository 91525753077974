import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FroalaEditor, { FroalaEvents } from 'froala-editor';
import {
  Node,
  Parser,
  PreprocessingInstruction,
  ProcessingInstruction,
  ProcessNodeDefinitions,
} from 'html-to-react';
import { useDrop } from 'react-dnd';
import camelcaseKeys from 'camelcase-keys';
import {
  ComplexRichTextFieldData,
  DefinitionBlock,
  fieldToImage,
  FieldType,
  hostedUrls,
  ImageFieldData,
  imageToField,
  isImageFieldData,
  isImageFieldDataArray,
  isLinksFieldDataArray,
  isRTHFieldDataArray,
  isSocialsFieldDataArray,
  isVideoFieldData,
  LinkFieldData,
  PlainTextFieldData,
  SocialFieldData,
  TextFieldData,
  toVideoFieldData,
} from 'models/publisher/block';
import { ImageData } from 'models/image';
import { VideoProcessingStep } from 'models/video';
import { useProgram, useProgramIdState } from 'contexts/program';
import { useFroalaPlugin } from 'hooks/inclusive-language/useFroalaPlugin';
import { useInlineDropzone } from 'components/publisher/blocks/instances/useInlineDropzone';
import { useUniqueId } from 'hooks/useUniqueId';
import { useProcessedContentImage } from 'hooks/useContentImage';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useUser } from 'contexts/user';
import { useFroalaLiquidPlugin } from 'hooks/liquid-variables/useFroalaPlugin';
import { useFroalaHorizontalRulePlugin } from 'hooks/horizontal-rule/useFroalaHorizontalRulePlugin';
import { froalaIndentationOptions } from 'hooks/froala-indentations/froalaIndentationOptions';
import { TipTapFieldEditor } from 'components/publisher/blocks/instances/TipTapFieldEditor';
import { createFlashError } from 'models/flash-message';
import { useFlashMessage } from 'contexts/flasher';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { PickerModal as BoxPickerModal } from 'shared/Box/PickerModal';
import * as Froala from './froala-configs';
import styles from './editor.module.css';
import { useVideo } from '../forms/fields/Video/hooks/useVideo';
import { UploadedVideoPreview } from '../forms/fields/Video/components/VideoFile/UploadedVideoPreview';
import { LANGS_UNICODE } from '../constants';
import { LoadingSpinner } from '../../../../shared/LoadingSpinner';
import {
  useFroalaSummarizeWithAi,
  useSummarizeWithAiContext,
} from './SummarizeWithAi/summarize-with-ai';
import { SummarizeWithAiModal } from './SummarizeWithAi/summarize-with-ai-modal';
import { CodeViewCallback } from './useEditableInstance';
import {
  fixCodeViewToolbar,
  injectPluginsAndToolbarButtons,
} from './froalaCodeViewUtils';
import { addFileUploadPlugin } from './FileUploadPlugin/addFileUploadPlugin';
import { addVideoPlugin } from './froalaVideoPluginUtils';
import { useFroalaBoxPlugin } from './BoxPlugin';

const FieldEditor: React.FC<{
  initialValue: string;
  blockId: string;
  blockName: string;
  kind: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  codeViewCallback: CodeViewCallback;
  showCodeViewButton?: boolean;
  froalaOptions: Froala.Options;
}> = ({
  initialValue,
  kind,
  blockId,
  blockName,
  onChange,
  onFocus,
  onBlur,
  codeViewCallback,
  showCodeViewButton,
  froalaOptions: froalaOverrides,
}) => {
  const uuid = useUniqueId();
  const { id: programId } = useProgram();
  const editor = useRef<FroalaEditor>();
  const { id } = useUser();
  const { events: inclusiveLanguageEvents } = useFroalaPlugin(programId);
  const { data: useLiquidVariables } = useFeatureFlagsQuery(
    programId,
    'Studio.LiquidVariables'
  );
  const [codeViewEnabled, setCodeViewEnabled] = useState(false);
  const {
    setSummarizableContent,
    setCommand,
    createSummarizationTask,
    summarizableContent,
    command,
  } = useSummarizeWithAiContext();

  const { events: liquidEvents } = useFroalaLiquidPlugin(
    programId,
    id,
    useLiquidVariables?.value
  );

  useFroalaHorizontalRulePlugin();
  useFroalaSummarizeWithAi({
    setSummarizableContent,
    setCommand,
    createSummarizationTask,
    editor: editor.current,
  });

  const { selected } = React.useContext(BlocksEditorContext);

  const useFroalaImages =
    useFeatureFlagsQuery(programId, 'Studio.Publish.FroalaEditorImages').data
      ?.value ?? undefined;

  const useFroalaFileUploadPlugin =
    useFeatureFlagsQuery(
      programId,
      'Studio.Publish.FroalaEditorFileUploadPlugin'
    ).data?.value ?? undefined;

  const useFroalaVideoPlugin =
    useFeatureFlagsQuery(programId, 'Studio.Publish.FroalaVideoPlugin').data
      ?.value ?? undefined;

  const useLinksOpenInNewTab = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.DefaultLinksOpenInNewTab'
  ).data?.value;
  const useBoxIntegration = useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement'
  ).data?.value;

  const {
    isBoxPickerOpen,
    setIsBoxPickerOpen,
    onSelectBoxResource,
    froalaEvents: boxPluginEvents,
  } = useFroalaBoxPlugin({
    enabled: !!useBoxIntegration,
  });

  const insertLinkEvents = useMemo<Partial<FroalaEvents>>(
    () =>
      useLinksOpenInNewTab
        ? {
            // eslint-disable-next-line func-names
            'commands.after': function (this: FroalaEditor, cmd) {
              if (cmd !== 'insertLink') return;
              // eslint-disable-next-line react/no-this-in-sfc
              this.popups
                .get('link.insert')
                .find('input.fr-link-attr[type="checkbox"]')
                .attr('checked', 'true');
            },
          }
        : {},
    [useLinksOpenInNewTab]
  );

  useEffect(() => {
    if (!codeViewEnabled) return;
    codeViewCallback(uuid, () => {
      // init the editor by triggering a mousedown event
      if (editor.current && !editor.current?.codeView) {
        const editorNode = document
          .getElementById(uuid)
          ?.getElementsByClassName('fr-view')[0] as HTMLElement;
        editorNode?.dispatchEvent(new Event('mousedown'));
      }
      if (editor.current && editor.current.codeView) {
        editor.current.codeView.toggle();
      }
    });
  }, [uuid, codeViewCallback, codeViewEnabled]);

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const onFocusRef = useRef(onFocus);
  onFocusRef.current = onFocus;
  const onBlurRef = useRef(onBlur);
  onBlurRef.current = onBlur;

  const initEditor = useCallback(
    async (node) => {
      if (!node || editor.current?.node?.isElement(node)) return;

      let froalaOptions = Froala.makeEditorOptions(kind, {
        ...froalaOverrides,
        imagePasteProcess: true,
        events: {
          ...inclusiveLanguageEvents,
          ...insertLinkEvents,
          ...boxPluginEvents,

          'commands.before': function commandsBefore(
            this: FroalaEditor,
            cmd: string
          ) {
            // Returning false cancels the froala command
            return froalaIndentationOptions.events['commands.before'].call(
              this,
              cmd
            );
          },

          'buttons.refresh': function buttonsRefresh(this: FroalaEditor) {
            // Returning false cancels the froala button refresh
            return froalaIndentationOptions.events['buttons.refresh'].call(
              this
            );
          },

          // The event handler for when the content in the FroalaEditor changes.
          contentChanged(this: FroalaEditor) {
            // eslint-disable-next-line react/no-this-in-sfc
            const currentHtml = this.doc.getElementById(uuid);
            if (blockName !== 'email_link') {
              ensureTextAlignment(currentHtml);
              ensureTablePadding(currentHtml);
            }

            if (
              inclusiveLanguageEvents &&
              inclusiveLanguageEvents.initializationDelayed
            ) {
              inclusiveLanguageEvents.initializationDelayed.apply(this);
            }
            // eslint-disable-next-line react/no-this-in-sfc
            onChangeRef.current(this.html.get());
          },

          initializationDelayed(this: FroalaEditor) {
            liquidEvents.initializationDelayed.apply(this);
            if (
              inclusiveLanguageEvents &&
              inclusiveLanguageEvents.initializationDelayed
            ) {
              inclusiveLanguageEvents.initializationDelayed.apply(this);
            }
          },

          focus(this: FroalaEditor) {
            if (inclusiveLanguageEvents.focus)
              inclusiveLanguageEvents.focus.apply(this);
            onFocusRef.current();
            editor.current = this;
          },
          blur(this: FroalaEditor) {
            onBlurRef.current();
          },
        },
      });
      if (showCodeViewButton && kind === 'rich-text') {
        froalaOptions = injectPluginsAndToolbarButtons(
          froalaOptions,
          ['codeView'],
          ['html']
        );
      }
      setCodeViewEnabled(!!froalaOptions.pluginsEnabled?.includes('codeView'));

      if (useFroalaImages) {
        froalaOptions = await Froala.addImagePlugin(froalaOptions, programId);
      }

      if (useFroalaVideoPlugin) {
        froalaOptions = addVideoPlugin(froalaOptions);
      }

      if (useFroalaFileUploadPlugin) {
        froalaOptions = await addFileUploadPlugin(froalaOptions, programId);
      }

      const froala = new FroalaEditor(node, froalaOptions, () => {
        // This is a callback that is called when the editor is fully initialized.
        // We can add more event listeners and conditional logic here.
        if (showCodeViewButton && froala.codeView) {
          fixCodeViewToolbar(froala);
        }
      });
      editor.current = froala;
    },
    [
      kind,
      froalaOverrides,
      inclusiveLanguageEvents,
      boxPluginEvents,
      insertLinkEvents,
      showCodeViewButton,
      useFroalaImages,
      useFroalaVideoPlugin,
      useFroalaFileUploadPlugin,
      uuid,
      blockName,
      liquidEvents,
      programId,
    ]
  );

  // Developer Notes:
  // https://wysiwyg-editor.froala.help/hc/en-us/articles/360001329125-Can-I-change-an-option-after-initializing-the-editor-
  // https://froala.com/wysiwyg-editor/examples/destroy-init/
  const [enabledFroalaImages, setEnabledFroalaImages] = React.useState(false);
  const [
    enabledFroalaFileUploadPlugin,
    setEnabledFroalaFileUploadPlugin,
  ] = React.useState(false);
  React.useEffect(() => {
    const froalaImagesWasEnabled = !enabledFroalaImages && useFroalaImages;
    const froalaFileUploadPluginWasEnabled =
      !enabledFroalaFileUploadPlugin && useFroalaFileUploadPlugin;
    const isReasonToReinitializeEditor =
      froalaImagesWasEnabled || froalaFileUploadPluginWasEnabled;
    if (isReasonToReinitializeEditor && editor.current?.node) {
      const node = editor.current.$oel.get(0);
      setEnabledFroalaImages(!!useFroalaImages);
      setEnabledFroalaFileUploadPlugin(!!useFroalaFileUploadPlugin);
      editor.current.destroy();
      initEditor(node);
    }
  }, [
    enabledFroalaImages,
    useFroalaImages,
    enabledFroalaFileUploadPlugin,
    useFroalaFileUploadPlugin,
    initEditor,
  ]);

  // This will initialize the editor with the initialValue, but will not change
  // the value out from under the active editor.
  // The content still gets styled when changes are made on the design screen.
  const [editorElement] = useState(
    <div
      id={uuid}
      className={styles.editor}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: initialValue }}
      ref={initEditor}
    />
  );

  const handleModalConfirm = (content: string) => {
    if (!editor.current?.html) {
      return;
    }
    // Restore the selection as the modal will have taken focus away from the editor
    editor.current.selection.restore();

    const hasSelection = Boolean(editor.current.selection?.text());

    if (hasSelection) {
      editor.current.html.insert(content);
    } else {
      editor.current.html.set(content);
    }
  };

  const handleModalCancel = () => {
    if (!editor.current) {
      return;
    }
    // Saving the selection before the modal opens seems to lock the selection
    // So we need to restore it after the modal closes
    editor.current.selection.restore();
  };

  const isSelectedEditor = selected?.id === blockId;
  // derive modal showing state if the block is selected and we have content to summarize
  const shouldShowSummarizeModal = summarizableContent && isSelectedEditor;

  return (
    <>
      {shouldShowSummarizeModal && (
        <SummarizeWithAiModal
          key={uuid}
          onModalConfirm={handleModalConfirm}
          onModalCancel={handleModalCancel}
          command={command}
        />
      )}

      {useBoxIntegration && isBoxPickerOpen && (
        <BoxPickerModal
          description="Select the file or folder content to link in the campaign"
          type={['file', 'folder']}
          onSubmit={onSelectBoxResource}
          onCancel={() => setIsBoxPickerOpen(false)}
          restrictByPermission
        />
      )}

      {editorElement}
    </>
  );
};

// This component is responsible for updating any images that are unprocessed with its processed data
// It currently renders nothing, only existing as a component so that it can be instantiated multiple times
// this allows us to refetch on multiple images at a time
const ContentImage: React.FC<{
  imageId: string;
  block: DefinitionBlock;
  onChange: (fieldName: string, data: FieldType) => void;
}> = ({ imageId, block, onChange }) => {
  const { setFlashMessage } = useFlashMessage();

  const image: ImageFieldData | undefined = useMemo(() => {
    if (
      isImageFieldData(block.field_data.image) &&
      block.field_data.image.image_id?.toString() === imageId
    ) {
      return block.field_data.image;
    }
    if (isImageFieldDataArray(block.field_data.images)) {
      return block.field_data.images.find(
        (img) => img.image_id?.toString() === imageId
      );
    }
    if (isLinksFieldDataArray(block.field_data.links)) {
      return block.field_data.links.find(
        (link) => link.image.image_id?.toString() === imageId
      )?.image;
    }

    return undefined;
  }, [block.field_data, imageId]);

  const changeHandler = useCallback(
    (data: ImageData) => {
      const imgId = data?.imageId ?? image?.image_id;

      if (
        block.name === 'images' &&
        isImageFieldDataArray(block.field_data?.images)
      ) {
        const { images } = block.field_data;
        const index = images.findIndex(
          (i: ImageFieldData) => i.image_id === imgId
        );

        if (index !== -1) {
          images[index] = imageToField(data);
          onChange('images', images);
        }
      } else if (
        block.name === 'links' &&
        isLinksFieldDataArray(block.field_data?.links)
      ) {
        const { links } = block.field_data;
        const index = links.findIndex(
          (l: LinkFieldData) => l.image.image_id === imgId
        );

        if (index !== -1) {
          links[index].image = imageToField(data);
          onChange('links', links);
        }
      } else {
        onChange('image', imageToField(data));
      }
    },
    [block.field_data, block.name, image?.image_id, onChange]
  );

  useProcessedContentImage({
    onProcessed: changeHandler,
    image: image ? fieldToImage(image) : undefined,
    onError: (error) => {
      setFlashMessage(createFlashError(error));
      changeHandler({
        altText: 'Add an Image',
        url: hostedUrls.blankImage,
        processed: true,
        isPlaceholder: true,
      });
    },
  });

  return <></>;
};

type NodeWithChildren = Node & { children: NodeWithChildren[] };

// This component preloads processed images that contain cloudinary transformations
// before displaying them to avoid showing a stretched image when the aspect ratio changes.
const ProcessedImage: React.FC<{
  node: NodeWithChildren;
}> = ({ node }) => {
  // Finds the image node matching the processed image src
  const imageNode = useMemo(() => {
    function findImageNode(children: NodeWithChildren[]) {
      let foundNode: Node | undefined;
      children.some((child) => {
        if (child.children.length) {
          foundNode = findImageNode(child.children);
        } else if (
          child.attribs.src === node.attribs['data-processed-image-src']
        ) {
          foundNode = child;
        }
        return !!foundNode;
      });
      return foundNode;
    }

    return findImageNode(node.children);
  }, [node.attribs, node.children]);

  // Extracts image attributes
  const { src: imgSrc, style: imgStyle = '', ...attrs } =
    imageNode?.attribs ?? {};

  // Preloads the image
  const [src, setSrc] = useState<string>(imgSrc);
  useEffect(() => {
    if (src && src !== imgSrc) {
      setSrc('');
    }

    const tempImg = new Image();
    tempImg.onload = () => setSrc(imgSrc);
    tempImg.onerror = () => setSrc(imgSrc);
    tempImg.src = imgSrc;
  }, [imgSrc, src]);

  // Converts the style string to an object
  const style = useMemo(
    () =>
      imgStyle
        ? camelcaseKeys(
            imgStyle.split(';').reduce((acc, curr) => {
              const [key, value] = curr.split(':');
              return { ...acc, [key]: value };
            }, {})
          )
        : {},
    [imgStyle]
  );

  // Renders a loading spinner if the image is still loading
  if (!src)
    return (
      <div className={styles.loadingOverlay}>
        <LoadingSpinner />
      </div>
    );

  return imageNode?.name === 'video' ? (
    <GifVideo src={src} />
  ) : (
    React.createElement(imageNode?.name ?? 'img', { src, style, ...attrs })
  );
};

const GifVideo: React.FC<{ src: string }> = ({ src }) => (
  <video
    width="100%"
    autoPlay
    muted
    loop
    playsInline
    preload="auto"
    src={src}
  />
);

// This component renders the UploadedVideoPreview component which
// will show transcoding progress while the video is processing.
const ContentVideo: React.FC<{
  videoId: string;
  block: DefinitionBlock;
  onChange: (fieldName: string, data: FieldType) => void;
}> = ({ videoId, block, onChange }) => {
  const fieldData =
    isVideoFieldData(block.field_data.video) &&
    block.field_data.video.video_id?.toString() === videoId
      ? toVideoFieldData(block.field_data.video)
      : undefined;

  const { video, uploadProcessingStatus, resetVideoStatus } = useVideo({
    fieldData,
    onChange: (data) => onChange('video', data),
    autoplay: false,
  });

  useEffect(() => {
    resetVideoStatus(parseInt(videoId, 10));
  }, [resetVideoStatus, videoId]);

  if (uploadProcessingStatus.step === VideoProcessingStep.Complete) return null;

  // Renders the transcoding progress within the block
  return (
    <div style={{ padding: '10px 25px' }}>
      <UploadedVideoPreview
        video={video}
        processingStatus={uploadProcessingStatus}
        showCaption={false}
      />
    </div>
  );
};

const preprocessingInstructions: PreprocessingInstruction[] = [
  {
    shouldPreprocessNode(node) {
      return (
        node.attribs?.style?.includes('-moz') ||
        node.attribs?.style?.includes('-webkit')
      );
    },
    preprocessNode(node) {
      // This preprocessor strips out moz and webkit vendor prefixes so
      // that we don't get a ton of warnings from react in the console.
      // They aren't super important in this editor, because firefox and webkit
      // will likely support the properties we're using natively.

      // If we find that we do need to allow these properties through, we can
      // copy and modify the default processor so that it doesn't camelcase
      // vendor prefixes (https://github.com/aknuds1/html-to-react/blob/master/lib/utils.js#L26)

      // eslint-disable-next-line no-param-reassign
      node.attribs.style = node.attribs.style
        .replace(/-moz[^;]*/g, '')
        .replace(/-webkit[^;]*/g, '');
    },
  },
];

// If a block does not fit the classic field_data shape, a custom change handler needs to be defined for it
function createFieldAndHandler(
  block: DefinitionBlock,
  node: Node,
  fieldName: string,
  onChange: (fieldName: string, data: FieldType) => void
) {
  if (block.name === 'links') {
    const linkFieldName = fieldName as Extract<
      keyof LinkFieldData,
      'description' | 'title' | 'callToAction'
    >;
    const uuid = node.attribs['data-editor-uuid'];
    const { links } = block.field_data;
    if (!isLinksFieldDataArray(links))
      throw new Error(
        `Links field_data is not an array or array element is not a link`
      );

    const index = links.findIndex((link: LinkFieldData) => link.uuid === uuid);
    const field = links[index][linkFieldName];
    return {
      field,
      enterOption: FroalaEditor.ENTER_BR,
      onFieldChange: (value: string) => {
        links[index][linkFieldName] = { ...field, value };
        onChange('links', links);
      },
    };
  }

  if (block.name === 'social') {
    const socialFieldName = fieldName as Extract<
      keyof SocialFieldData,
      'title'
    >;
    const uuid = node.attribs['data-editor-uuid'];
    const { social } = block.field_data;
    if (!isSocialsFieldDataArray(social))
      throw new Error(
        `Social field_data is not an array or array element is not a social`
      );

    const index = social.findIndex(
      (link: SocialFieldData) => link.uuid === uuid
    );
    const field = social[index][socialFieldName] as PlainTextFieldData;
    return {
      field,
      enterOption: FroalaEditor.ENTER_BR,
      onFieldChange: (value: string) => {
        social[index][socialFieldName] = { ...field, value };
        onChange('social', social);
      },
    };
  }

  if (block.name === 'rich_texts_with_header') {
    const rtwhFieldName = fieldName as Extract<
      keyof ComplexRichTextFieldData,
      'header' | 'markup'
    >;
    const { texts } = block.field_data;
    if (!isRTHFieldDataArray(texts))
      throw new Error(
        `Rich text with header field_data is not an array or array element is not a rich text with header`
      );

    const uuid = node.attribs['data-editor-uuid'];
    const index = texts.findIndex((text) => text.uuid === uuid);
    const field = texts[index][rtwhFieldName];
    return {
      field,
      onFieldChange: (value: string) => {
        texts[index][rtwhFieldName].value = value;
        onChange('texts', texts);
      },
    };
  }

  const field = block.field_data[fieldName] as TextFieldData;
  return {
    field,
    onFieldChange: (value: string) => onChange(fieldName, { ...field, value }),
  };
}

/* Parse the provided html and insert Froala Editors in place of elements
   with a class of js-editor */
export const InlineEditor: React.FC<{
  blockId: string;
  html: string;
  block: DefinitionBlock;
  onChange: (fieldName: string, data: FieldType) => void;
  onFocus: () => void;
  onBlur: () => void;
  onDropzoneUploading: (isUploading: boolean) => void;
  codeViewCallback: CodeViewCallback;
  showCodeViewButton?: boolean;
  toolbarId: string;
  disableFroala: boolean;
}> = ({
  blockId,
  html,
  block,
  onChange,
  onFocus,
  onBlur,
  onDropzoneUploading,
  codeViewCallback,
  showCodeViewButton,
  toolbarId,
  disableFroala,
}) => {
  /*
      Our rendered html from donkey contains a whole html document wrapping the block.
      This InlineEditor works by converting the html to JSX components, and we're not
      allowed to have <html>, <body> or <head> tags when we do that.

      This converts them divs so that we can create jsx components and replace specific
      nodes with editable components.
     */
  const stripped = html
    .replace(/<(html|body|head)/g, '<div')
    .replace(/<\/(html|body|head)>/g, '</div>');

  const htmlToReactParser = new Parser();
  const processNodeDefinitions = new ProcessNodeDefinitions();
  const isValidNode = () => true;

  const [programId] = useProgramIdState();

  const tiptap = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.TipTapTextEdit'
  )?.data?.value;

  const useNewEditor = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  )?.data?.value;

  const processingInstructions: ProcessingInstruction[] = [
    {
      // This is REQUIRED, it tells the parser
      // that we want to insert our React
      // component as a child
      replaceChildren: true,
      shouldProcessNode: (node) => {
        if (!node.attribs) {
          return false;
        }

        if (useNewEditor && node.attribs['data-editor-field'] === 'iframe') {
          return false;
        }

        if (node.attribs['data-image-numeric-id']) {
          return true;
        }

        if (node.attribs['data-processed-image-src']) {
          return true;
        }

        if (
          node.attribs['data-video-numeric-id'] &&
          node.attribs.class === 'video-processing'
        ) {
          return true;
        }

        return (
          !disableFroala &&
          node.attribs.class === 'js-editor' &&
          node.attribs['data-editor-editable'] === 'true'
        );
      },
      processNode: (node) => {
        // handle processing images within the block
        if (node.attribs['data-image-numeric-id'])
          return (
            <ContentImage
              imageId={node.attribs['data-image-numeric-id']}
              onChange={onChange}
              block={block}
            />
          );

        // handle processing transformed images within the block
        if (node.attribs['data-processed-image-src']) {
          return <ProcessedImage node={node as NodeWithChildren} />;
        }

        // handle processing videos within the block
        if (
          node.attribs['data-video-numeric-id'] &&
          node.attribs.class === 'video-processing'
        ) {
          return (
            <ContentVideo
              videoId={node.attribs['data-video-numeric-id']}
              onChange={onChange}
              block={block}
            />
          );
        }

        // This function modifyEditorValueTags replaces space after certain specifiied closing tags with &nbsp;
        const modifyEditorValueTags = (value: string): string => {
          const tagsToReplace = Object.freeze(['strong', 'em', 's', 'i', 'b']);

          const regexPattern = new RegExp(
            `<\\/(${tagsToReplace.join('|')})>\\s`,
            'g'
          );

          // Replacement logic for "</u> "
          const replacementForU = '</u><span>&nbsp;</span>';

          // Replace "</u> " with "</u><span>&nbsp;</span>"
          const modifiedValue = value.replace('</u> ', replacementForU);
          const replacement = '&nbsp;</$1>';

          return modifiedValue.replace(regexPattern, replacement);
        };

        // handle text editors within the block
        const fieldName = node.attribs['data-editor-field'];
        const { field, enterOption, onFieldChange } = createFieldAndHandler(
          block,
          node,
          fieldName,
          onChange
        );

        // An undefined value for the `enter` option causes the editor
        // to remove extra line breaks when backspace is pressed.
        const froalaOptions = {
          toolbarContainer: `#${toolbarId}`,
          ...(enterOption ? { enter: enterOption } : {}),
        };

        if (tiptap) {
          return (
            <TipTapFieldEditor
              field={fieldName}
              key={`${blockId}-${fieldName}`}
              kind={field.type}
              froalaOptions={froalaOptions}
              initialValue={field.value}
              onChange={(e) => {
                onFieldChange(e);
              }}
              onFocus={onFocus}
              codeViewCallback={codeViewCallback}
            />
          );
        }

        return (
          <FieldEditor
            key={`${blockId}-${fieldName}`}
            blockId={blockId}
            blockName={block.name}
            kind={field.type}
            froalaOptions={froalaOptions}
            initialValue={field.value}
            onChange={(e) => {
              onFieldChange(modifyEditorValueTags(e));
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            codeViewCallback={codeViewCallback}
            showCodeViewButton={showCodeViewButton}
          />
        );
      },
    },
    {
      // html-to-react arbitrarily removes key attributes from <video> tags.
      // This is a workaround to add them back.
      shouldProcessNode: (node) => {
        return (
          node.attribs && node.attribs.class === 'video-converted-from-gif'
        );
      },
      processNode: (node) => {
        return <GifVideo src={node.attribs.src} />;
      },
    },
    {
      // Anything else
      shouldProcessNode: () => {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const dropzone = useInlineDropzone({
    block,
    blockId,
    onChange,
    onUploading: onDropzoneUploading,
  });

  // turn off embedded drag and drop when we are using the blocks dragger
  const [{ inProgress }] = useDrop<unknown, unknown, { inProgress: boolean }>(
    () => ({
      accept: 'add-block-from-panel-to-canvas',
      collect: (monitor) => ({ inProgress: monitor.canDrop() }),
    })
  );

  // setup dropzone
  useEffect(() => {
    const runSetup = !inProgress;
    if (runSetup) dropzone.setup();
    return () => {
      if (runSetup) dropzone.uninstall();
    };
  }, [dropzone, inProgress]);

  return htmlToReactParser.parseWithInstructions(
    stripped,
    isValidNode,
    processingInstructions,
    preprocessingInstructions
  );
};

function ensureTablePadding(currentHtml: HTMLElement | null) {
  if (currentHtml) {
    currentHtml
      .querySelectorAll('.fr-view table td, .fr-view table th')
      .forEach((cell) => {
        const currentStyle = cell.getAttribute('style') || '';
        const hasPadding = currentStyle.includes('padding-left:20px;');
        const hasOrderedList = cell.querySelector('ol') !== null;

        // Add padding if it contains <ol> and doesn't already have padding
        if (hasOrderedList && !hasPadding) {
          cell.setAttribute(
            'style',
            `${currentStyle.trim()} padding-left:20px;`
          );
        }
        // Remove padding if it doesn't contain <ol> but has padding
        else if (!hasOrderedList && hasPadding) {
          const newStyle = currentStyle
            .replace('padding-left:20px;', '')
            .trim();
          if (newStyle) {
            cell.setAttribute('style', newStyle);
          } else {
            cell.removeAttribute('style');
          }
        }
      });
  }
}

function ensureTextAlignment(currentHtml: HTMLElement | null) {
  // iterates through the child elements of the editor
  // applies LTR/RTL alignment attributes to the elements based on the text content.
  if (currentHtml) {
    currentHtml
      .querySelectorAll('p,h1,h2,h3,h4,h5,li,td')
      .forEach((tempNode: Element) => {
        const isRightToLeftLangs = (text: string): boolean => {
          const firstWord = text?.trim().split(' ')[0];
          const rtlLangUnicodes = Object.values(LANGS_UNICODE).join('');
          const langsRegx = new RegExp(`[${rtlLangUnicodes}]`);
          return langsRegx.test(firstWord);
        };
        if (tempNode.removeAttribute) {
          const alignment =
            (tempNode as HTMLElement)?.style.textAlign ||
            tempNode.getAttribute('align');
          tempNode.removeAttribute('align');
          if (isRightToLeftLangs(tempNode.innerHTML)) {
            tempNode.setAttribute('align', 'right');
            tempNode.setAttribute('dir', 'rtl');
          } else {
            if (alignment) {
              tempNode.setAttribute('align', alignment);
            }
            tempNode.setAttribute('dir', 'ltr');
          }
        }
      });
  }
}
