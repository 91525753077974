import { useQuery, useInfiniteQuery } from 'react-query';
import { Design } from 'models/design';
import {
  DesignData,
  DesignsCollectionData,
  FetchProps,
  fetchById,
  fetchStatusById,
  fetchDesigns,
} from 'services/api-design';
import { UseDesign, defaultDesign } from 'contexts/design';
import { useCallback, useEffect, useState } from 'react';
import {
  QueryResponse,
  InfiniteQueryResponse,
  nextPageToFetch,
} from './common';
import { usePersistDesign } from './persist-design';
import { useFieldVariables } from './design/useFieldVariables';

export function mapServerDataToDesigns(
  serverData: DesignsCollectionData
): Array<Design> {
  return serverData.data.map((entity: DesignData) => entity.attributes);
}

export const useDesignsQuery = (
  props: FetchProps
): QueryResponse<Array<Design>> => {
  const { isLoading, error, data, isSuccess } = useQuery<
    DesignsCollectionData,
    Error
  >(['designs', { ...props }], () => fetchDesigns(props), {
    retry: false,
    onError: props.onError,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    isSuccess,
    data: data && mapServerDataToDesigns(data),
  };
};

export const useDesignsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<Design> => {
  const { programId, pageSize = 20 } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<DesignsCollectionData, Error>(
    ['designs-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchDesigns({
        programId,
        pageSize,
        page: pageParam as number,
      }),
    {
      cacheTime: 0,
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToDesigns(batch) : []))
      .flat(1);
  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

type DesignQueryProps = {
  programId: number;
  id: number | 'new';
};

export const useDesignQuery = (
  props: DesignQueryProps
): QueryResponse<Design> => {
  const { programId, id } = props;
  const { isLoading, error, data } = useQuery<Design, Error>(
    ['design/design', id, programId],
    {
      queryFn: () => fetchById(programId, id),
      cacheTime: 0,
    }
  );

  if (!data) {
    return {
      isLoading,
      errorMessage: error?.message,
      data: undefined,
    };
  }

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useDesignStatusQuery = (
  programId: number,
  id: number,
  shouldPollEndpoint: boolean
): QueryResponse<string> => {
  const { isLoading, error, data } = useQuery<string, Error>(
    ['design/status', id, programId],
    {
      queryFn: () => fetchStatusById(programId, id),
      refetchInterval: 5000,
      enabled: shouldPollEndpoint,
      refetchOnWindowFocus: true,
    }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export function useDesign(
  program_id: number,
  id: number | 'new',
  redirectOnSave?: boolean
): UseDesign {
  const [design, setDesign] = useState<Design>(defaultDesign);
  const [hasLoaded, setHasLoaded] = useState(false);

  const designQuery = useDesignQuery({
    programId: program_id,
    id,
  });
  const fieldVariables = useFieldVariables();

  useEffect(() => {
    setHasLoaded(false);
  }, [id]);

  useEffect(() => {
    if (designQuery.data && !hasLoaded) {
      setDesign({ ...designQuery.data, meta: fieldVariables });
      setHasLoaded(true);
    }
  }, [designQuery.data, fieldVariables, hasLoaded]);

  const update = useCallback(
    (changes: Design) => {
      const updatedDesign = {
        ...design,
        ...changes,
      };
      setDesign(updatedDesign);
    },
    [design]
  );

  const persistDesign = usePersistDesign(
    design,
    setDesign,
    false,
    redirectOnSave
  );

  const save = useCallback<UseDesign['save']>(
    ({ onSuccess, onError } = {}) => {
      persistDesign.save({ onSuccess, onError, design });
    },
    [design, persistDesign]
  );

  return {
    id,
    design,
    update,
    save,
    isProcessing: false,
    error: '',
    status: {
      isSaving: false,
      isLoading: designQuery.isLoading,
      hasLoaded,
    },
    active: true,
  };
}
