import React, { createContext, useContext, useState } from 'react';
import { Design } from 'models/design';
import { useJourneyState } from 'contexts/journey';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';

type JourneyContentDesignerContextProps = {
  isDesignerOpen: boolean;
  closeDesigner: () => void;
  onSelectDesign: (design: Design) => void;
  createDesign: () => void;
  editDesign: () => void;
  inlineEditingEnabled: boolean;
  designerId?: number | 'new';
};

const JourneyContentDesignerContext = createContext<
  JourneyContentDesignerContextProps
>({
  isDesignerOpen: false,
  closeDesigner: () => {},
  onSelectDesign: () => {},
  createDesign: () => {},
  editDesign: () => {},
  inlineEditingEnabled: false,
  designerId: 'new',
});

export const JourneyContentDesignProvider: React.FC = ({ children }) => {
  const [isDesignerOpen, setIsDesignerOpen] = useState(false);
  const [designerId, setDesignerId] = React.useState<
    number | 'new' | undefined
  >('new');
  const { id: programId } = useProgram();
  const inlineEditingEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.InlineEditing'
  ).data?.value;

  const { updateStep, activeStep } = useJourneyState();

  const onSelectDesign = React.useCallback(
    (design: Design) => {
      if (!activeStep || activeStep.type !== 'communication') return;
      updateStep({
        ...activeStep,
        approved: false,
        designId: Number(design.id),
      });
      setDesignerId(Number(design.id));
    },
    [activeStep, updateStep]
  );
  const createDesign = () => {
    setDesignerId('new');
    setIsDesignerOpen(true);
  };
  const editDesign = () => {
    if (!activeStep || activeStep.type !== 'communication') return;
    setDesignerId(Number(activeStep?.designId));
    setIsDesignerOpen(true);
  };
  const closeDesigner = () => {
    setIsDesignerOpen(false);
    setDesignerId(undefined);
  };

  return (
    <JourneyContentDesignerContext.Provider
      value={{
        isDesignerOpen,
        closeDesigner,
        onSelectDesign,
        createDesign,
        editDesign,
        inlineEditingEnabled,
        designerId,
      }}
    >
      {children}
    </JourneyContentDesignerContext.Provider>
  );
};

export const useJourneyContentDesigner: () => JourneyContentDesignerContextProps = () => {
  const context = useContext(JourneyContentDesignerContext);
  if (!context) {
    throw new Error(
      'useJourneyContentDesigner must be used within a JourneyContentDesignerProvider'
    );
  }
  return context;
};
